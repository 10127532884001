import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "./carousal.module.css";

const UsaFeatureText = () => {
  return (
    <>
      <span className="d-none d-md-block">
        <Divider className={styles.divider} />

        <Grid
          container
          justify="space-between"
          alignItems="start"
          direction="column"
        >
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            <span>
              <span className={styles.highlightedText}>
                High-converting Website Templates for Better Conversions -{" "}
              </span>{" "}
              More time to brew your coffee while your website brings in more
              clients.
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />

            <span>
              <span className={styles.highlightedText}>
                {" "}
                Powerful Booking & Scheduling Tool -{" "}
              </span>{" "}
              Industry&apos;s best booking & scheduling tool to launch and
              conduct courses, webinars, flexible classes, workshops, etc.
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            <span>
              <span className={styles.highlightedText}>
                {" "}
                In-built Email Marketing -{" "}
              </span>{" "}
              The hotline to your customer&apos;s inbox to service, retarget and
              build long-term relationships.
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            <span>
              <span className={styles.highlightedText}>
                {" "}
                Customer Experience and Automation -{" "}
              </span>{" "}
              Accept money straight into your account from anywhere in the
              world.
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            <span>
              <span className={styles.highlightedText}>
                {" "}
                Access to Advanced Sales Suite -{" "}
              </span>{" "}
              Build and send discount codes, special deals & offers, invoices,
              reminders, updates, etc.{" "}
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />

            <span>
              <span className={styles.highlightedText}>
                {" "}
                Unlimited Content Storage -{" "}
              </span>{" "}
              Build and send discount codes, special deals & offers, invoices,
              reminders, updates, etc., and{" "}
              <span className={styles.highlightedText}> Much More…. </span>{" "}
            </span>
          </div>
        </Grid>
        <br />
        <Divider />
      </span>
    </>
  );
};

export default React.memo(UsaFeatureText);
