import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "./carousal.module.css";

const IndiaFeatureText = () => {
  return (
    <>
      <span className="d-none d-md-block">
        <Divider className={styles.divider} />

        <Grid
          container
          justify="space-between"
          alignItems="start"
          direction="column"
        >
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            <span>
              Exly helps you build your webpage, set up online sessions and
              appointments, and accept digital payments.
            </span>
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            Customize your webpage by choosing from multiple themes and
            templates.
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            Upload your class and workshop schedule, digital courses, and
            pre-recorded content.
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            Accept online payments and manage your business digitally.
          </div>
          <div className={styles.featureText}>
            <CheckCircleIcon className={styles.verifiedIcon} />
            Run ads, design marketing creatives, create limited-time offers, and
            more.
          </div>
        </Grid>
        <br />
        <Divider />
      </span>
    </>
  );
};

export default React.memo(IndiaFeatureText);
