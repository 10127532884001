import React from "react";
import SignUp from "./SignUp";
import NotificationPopup from "ui/widgets/NotificationPopup";

const Onboarding = () => {
  return (
    <div>
      <NotificationPopup />
      <SignUp />
    </div>
  );
};

export default Onboarding;
