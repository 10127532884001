import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

import styles from "./carousal.module.css";

export const ProfileItems = (props) => {
  return (
    <Grid container justify="start" alignItems="center">
      <Avatar
        variant="square"
        alt="Remy Sharp"
        className={styles.avatarStyle}
        src={props.path}
      >
        S
      </Avatar>
      <div className={styles.nameOfPerson}>
        {props.name}
        <div className={styles.groupName}>{props.groupName}</div>
      </div>
      
    </Grid>
  );
};
