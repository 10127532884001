import React from "react";
import PropTypes from "prop-types";
import styles from "./onboarding.module.css";

const Divider = ({ children }) => {
  return (
    <div className={styles.dividerContainer}>
      <div className={styles.dividerBorder} />
      <span className={styles.dividerContent}>{children}</span>
      <div className={styles.dividerBorder} />
    </div>
  );
};

Divider.propTypes = {
  children: PropTypes.any,
};

export default Divider;
