import React, { useState } from "react";
import styles from "./onboarding.module.css";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GoogleLogin from "react-google-login";
import { refreshTokenSetup } from "../../../utils/handleToken";
import { is_empty } from "../../../utils/validations";
import dataProvider from "../../../data/dataProvider";
import { useLocation } from "react-router-dom";
import authProvider from "../../../authProvider";
import { SignUpCard } from "./carousal/signUpCard";
import {
  checkStaffLogin,
  hitAnalytic,
  sendOtp,
  setUtmDetails,
  getUtmDetails,
  tapAffiliate_clickId,
  fetchAndUpdateAuth,
  getUserID,
  removeUserID,
  handleLoginRedirect,
} from "../../../utils/AuthUtil";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import { getOnboardingContent } from "../../../utils/Utils";
import PreLoginAppBar from "./PreLoginAppBar";
import { useNotifications } from "utils/hooks";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { nutl } from "../newOnboarding/data";
import { useHistory } from "react-router";
import Divider from "./Divider";
import { redirectBasedOnCreatorOnboardingStage } from "features/Onboarding/utils/Onboarding.utils";

const GOOGLE_SIGNUP_FAILURE = 13;
const form_fields = {
  email: "Email ID",
};
let bdEmail = "";
const regexMobile = RegExp(/^[0-9]/);

const invalid_otp_analytics = () => {
  dataProvider.postAnalyticalEvent("onBoardingScreen1", "invalidOTP", {
    flow: "onboarding",
    funnel: "onboarding",
    user_input: "Tap",
    ui_component: "Header",
  });
};
const success_otp_analytics = () => {
  dataProvider.postAnalyticalEvent("onBoardingScreen1", "successOTP", {
    flow: "onboarding",
    funnel: "onboarding",
    user_input: "Tap",
    ui_component: "Header",
  });
};

const SignUp = () => {
  const { notify } = useNotifications();
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      try {
        dataProvider.postAnalyticalEvent(
          "onBoardingScreen1",
          "startOnBoarding",
          {
            flow: "onboarding",
            funnel: "onboarding",
            user_input: "Tap",
          }
        );
        dataProvider.postAnalyticalEvent("creatorPage", "successfullyLanded", {
          flow: "creatorPage",
          funnel: "creatorPage",
        });
        getOnboardingContent();
      } catch (err) {
        console.log("signup []", err);
      }
    })();
  }, []);

  const loadingString = "Send OTP / Verification Code";

  const [loginError, setLoginError] = useState(false);
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("Email is not valid");
  const [otp, setOtp] = React.useState("");
  const [otpFlag, setOtpFlag] = React.useState("");
  const [signUpLoad, setSignUpLoad] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState(loadingString);
  const [pageloaded, setpageloaded] = React.useState(false);
  const location = useLocation();
  const isDesktop = useDesktopMediaQuery();
  const [isNewUser, setIsNewUser] = React.useState(false);
  const [progressValue, setProgressValue] = React.useState("20");
  const [tap_click_id, setTapClickId] = React.useState("");
  let stored_email = window.sessionStorage.getItem("email_id");
  let stored_name = window.sessionStorage.getItem("name");

  if (!is_empty(stored_email) && stored_email !== "undefined") {
    stored_email = JSON.parse(stored_email);
  }

  if (!is_empty(stored_name)) {
    stored_name = JSON.parse(stored_name);
  }

  const captureOtp = ({ target }) => {
    if (regexMobile.test(target.value) || is_empty(target.value)) {
      setOtp(target.value);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const affiliate_clickId = await tapAffiliate_clickId();
        setTapClickId(affiliate_clickId);
        if (!is_empty(location.search)) {
          const queryString = location.search;
          const urlParams = new URLSearchParams(queryString);
          const email = urlParams.get("email");
          if (email) {
            setUtmDetails(location);
            window.sessionStorage.setItem("email_id", JSON.stringify(email));
            hitAnalytic();
            const emailId = email && email.trim();
            const params = {
              email: emailId,
            };
            const data = await sendOtp(params);
            if (data.data.data.status === "success") {
              setOtpFlag(true);
              setEmail(email);
              setIsNewUser(!data.data.data.isLogin);
              setProgressValue("40");
            }
          }
        }

        const user = getUserID();
        if (!is_empty(user)) {
          handleLoginRedirect();
        } else {
          setpageloaded(true);
        }
      } catch (err) {
        setpageloaded(true);
        console.log(err);
        notify(err?.body?.data?.error || "Something went wrong", "error");
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_empty(stored_email) && stored_email !== "undefined") {
      setEmail(stored_email);
    }
  }, [stored_email]);
  React.useEffect(() => {
    setEmail(location.email);
  }, [location]);

  //writing this function inside the component as we need the location
  const handleRedirectToSignUpPage = () => {
    removeUserID();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.append(nutl, true);
    history.push(`/signup?${urlParams}`);
  };

  const onGoogleLoginSuccess = async (res) => {
    dataProvider.postAnalyticalEvent(
      "onBoardingScreen1",
      "successfullyContinueGoogle",
      { flow: "onboarding", funnel: "onboarding", user_input: "Tap" }
    );
    setSignUpLoad(true);
    if (res.profileObj.googleId) {
      res.profileObj.id = res.profileObj.googleId;
      delete res.profileObj.googleId;
    }
    let utm_info = getUtmDetails();
    if (tap_click_id) {
      utm_info = {
        ...utm_info,
        tap_click_id: tap_click_id,
      };
    }
    const payload = {
      grant_type: "google",
      google_data: res.profileObj,
      access_token: res.tokenId,
      joining_info: {
        medium: "exlyapp",
        source: "creator_tool",
        campaign: "exly",
      },
      exly_utm_info: !is_empty(utm_info) ? utm_info : {},
    };
    const { status, error, signup_status } = await authProvider.login({
      grantType: "google",
      loginPayload: payload,
    });
    const uuid = getUserID();
    if (status === "success" && !is_empty(uuid)) {
      fetchAndUpdateAuth();
      dataProvider.postAnalyticalEvent("creatorPage", "login", {
        flow: "creatorPage",
        funnel: "creatorPage",
      });
      redirectBasedOnCreatorOnboardingStage({
        forceFetchProgressSteps: true,
        fetchAll: true,
        fallbackEnterCT: true,
      });
    }
    if (
      status === GOOGLE_SIGNUP_FAILURE ||
      signup_status === GOOGLE_SIGNUP_FAILURE
    ) {
      dataProvider.postAnalyticalEvent("creatorPage", "registered", {
        flow: "creatorPage",
        funnel: "creatorPage",
      });
      // commenting it as we are disabling onboading guide flow as of now
      // window.localStorage.setItem("isNewUser", false);
      window.localStorage.setItem("isNewUserWatchVideo", true); // displaying video for the new users can be handled with same flag once onboarding guide is enabled again
      if (typeof window !== "undefined") {
        window.gtag("event", "CompleteRegistration", {
          send_to: "AW-704617518/GYTcCOCR_vYBEK64_s8C",
        });
        window.fbq("track", "CompleteRegistration", {
          currency: "INR",
          value: 0.0,
        });
      }
      window.sessionStorage.setItem(
        "email_id",
        JSON.stringify(res.profileObj.email)
      ); //Since email field is not filled manually we get email from profile obj
      handleRedirectToSignUpPage();
    } else {
      if (error) notify(error, "error");
    }

    refreshTokenSetup(res);
  };

  const onGoogleLoginFailure = (res) => {
    console.log("Google login Failure", res);
    if (res.error && res.error === "popup_closed_by_user") return;
  };
  const resendOtp = async () => {
    dataProvider.postAnalyticalEvent("onBoardingScreen1", "resendOTP", {
      flow: "onboarding",
      funnel: "onboarding",
      user_input: "Tap",
      ui_component: "Text Button",
    });
    setOtp("");
    const resend = true;
    if (!is_empty(email)) {
      const params = {
        email: email,
      };
      const options = {
        resend,
      };
      const data = await dataProvider.sendOtp(params, options);
      if (data.data.data.status === "success") {
        notify("OTP / Verification Code resent successfully", "success");
      }
    } else {
      notify("Email is not valid", "warning");
    }
  };
  let mail;
  const regexEmail = RegExp(/^([\w.%+-]+[a-zA-Z0-9])@([\w-]+\.)+([\w]{2,})$/i);
  const handleNext = (e) => {
    mail = e.target.value;
    if (!is_empty(mail)) {
      if (!regexEmail.test(mail.trim())) {
        setErrorText(`Email is not valid`);
        setSignUpLoad(false);
        // setLoginError(true)
      } else {
        setErrorText(``);
        // setEmail(mail)
        // setNewUser(location.isNewUser);
        setLoginError(false);
      }
    } else {
      setEmail(mail);
      setLoginError(false);
    }
  };
  const getEmail = (value) => {
    return is_empty(value) ? email : value;
  };
  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      setSignUpLoad(true);
      if (!is_empty(email)) {
        setUtmDetails(location);
        const staffLogin = checkStaffLogin(email);
        bdEmail = staffLogin.bd_email;
        //bd email set to localStorage
        if (!is_empty(bdEmail)) {
          localStorage.setItem("bdEmail", bdEmail);
        }
        if (staffLogin.id === "invalid") {
          setSignUpLoad(false);
          setErrorText(`Email is not valid`);
          setLoginError(true);
        } else {
          hitAnalytic(staffLogin.id, bdEmail);
          const login_email = getEmail(staffLogin.id);
          if (!regexEmail.test(login_email.trim())) {
            setErrorText(`Email is not valid`);
            setSignUpLoad(false);
            return setLoginError(true);
          }
          window.sessionStorage.setItem(
            "email_id",
            JSON.stringify(login_email)
          );
          window.sessionStorage.setItem(
            "name",
            JSON.stringify(email.substring(0, email.indexOf("@")))
          );
          setLoadingText("Sign Up");
          const emailId = email && email.trim();
          const params = {
            email: emailId,
          };
          const data = await sendOtp(params);
          if (data?.data?.data?.status === "success") {
            setEmail(login_email);
            setIsNewUser(!data.data.data.isLogin);
            setOtpFlag(true);
            setProgressValue("40");
            setSignUpLoad(false);
            if (!data?.data?.data?.isLogin) handleRedirectToSignUpPage();
          }
        }
      } else {
        setLoadingText(loadingString);
        setErrorText("Please Enter Valid Email Id");
        setLoginError(true);
        setSignUpLoad(false);
      }
    } catch (err) {
      console.log(err);
      setLoadingText(loadingString);
      setErrorText(
        err?.body?.data?.error ? err?.body?.data?.error : "Something went wrong"
      );
      setLoginError(true);
      setSignUpLoad(false);
    }
  };
  const validateOtp = async (e) => {
    e.preventDefault();
    setSignUpLoad(true);
    dataProvider.postAnalyticalEvent("onBoardingScreen1", "verifyOTP", {
      flow: "onboarding",
      funnel: "onboarding",
      user_input: "Tap",
      ui_component: "Button",
    });
    if (!is_empty(otp)) {
      const utm_info = getUtmDetails();
      const emailId = email && email.trim();
      const params = {
        email: emailId,
        otp: otp,
        joining_info: {
          medium: "exlyapp",
          source: "creator_tool",
          campaign: "exly",
        },
        exly_utm_info: !is_empty(utm_info) ? utm_info : {},
      };
      if (isNewUser) {
        if (tap_click_id) {
          params.exly_utm_info = {
            ...params.exly_utm_info,
            tap_click_id: tap_click_id,
          };
        }
        Object.assign(params, { name: email.substring(0, email.indexOf("@")) });
        try {
          const { error, status } = await authProvider.login({
            grantType: "emailNew",
            loginPayload: params,
          });
          if (status === "success") {
            await fetchAndUpdateAuth();
            dataProvider.postAnalyticalEvent("creatorPage", "registered", {
              flow: "creatorPage",
              funnel: "creatorPage",
            });
            success_otp_analytics();
            // commenting it as we are disabling onboading guide flow as of now
            // window.localStorage.setItem("isNewUser", false);
            window.localStorage.setItem("isNewUserWatchVideo", true); // displaying video for the new users can be handled with same flag once onboarding guide is enabled again
            if (typeof window !== "undefined") {
              window.gtag("event", "CompleteRegistration", {
                send_to: "AW-704617518/GYTcCOCR_vYBEK64_s8C",
              });
              window.fbq("track", "CompleteRegistration", {
                currency: "INR",
                value: 0.0,
              });
            }
            handleRedirectToSignUpPage();
          } else {
            setSignUpLoad(false);
            invalid_otp_analytics();
            notify(error || "Unable to login", "error");
          }
        } catch (e) {
          setSignUpLoad(false);
          notify("Something went wrong", "error");
        }
      } else {
        const { error, status } = await authProvider.login({
          grantType: "emailOld",
          loginPayload: params,
        });
        const uuid = getUserID();
        if (status === "success" && !is_empty(uuid)) {
          dataProvider.postAnalyticalEvent("creatorPage", "login", {
            flow: "creatorPage",
            funnel: "creatorPage",
          });
          success_otp_analytics();
          await fetchAndUpdateAuth();
          redirectBasedOnCreatorOnboardingStage({
            forceFetchProgressSteps: true,
            fetchAll: true,
            fallbackEnterCT: true,
          });
        } else {
          setSignUpLoad(false);
          invalid_otp_analytics();
          notify(error || "Unable to login", "error");
        }
      }
    } else {
      setSignUpLoad(false);
      notify("Please enter OTP / Verification Code", "warning");
    }
  };
  return pageloaded ? (
    <form>
      {!isDesktop ? <PreLoginAppBar /> : null}
      <div className={styles.progressBar}>
        <div className={styles.linearhover}>
          <LinearProgress variant="determinate" value={progressValue} />
          <div
            className={styles.percentagevalue}
            style={{ left: `${progressValue}%` }}
          >
            {progressValue}% complete
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <div className={styles.carousalDetail}>
              <SignUpCard />
            </div>
            <div className={styles.containerSignup}>
              <Card className={styles.signUpCard}>
                <div
                  className={styles.exly}
                  style={{ display: !isDesktop ? "none" : "" }}
                >
                  Exly
                </div>
                <div className={styles.orText}>Sign Up or Sign In</div>
                <div className={`${styles.getSentence}`}>
                  Launch, Manage, and Grow Your Business Online
                </div>
                <div className={styles.signInDiv}>
                  <>
                    <div
                      className={styles.signInButton}
                      onClick={() => {
                        dataProvider.postAnalyticalEvent(
                          "onBoardingScreen1",
                          "continueGoogle",
                          {
                            flow: "onboarding",
                            funnel: "Onboarding",
                            user_input: "Tap",
                            ui_component: "Button",
                          }
                        );
                      }}
                    >
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}
                        buttonText="Sign up with Google"
                        onSuccess={onGoogleLoginSuccess}
                        onFailure={onGoogleLoginFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                    <div style={{ width: "100%", paddingTop: "10px" }}>
                      <Divider>Or</Divider>
                    </div>
                  </>
                  <span>{form_fields.form_fields}</span>
                  <span className={`${styles.getStarted} ${styles.feildLabel}`}>
                    Email ID
                  </span>
                  <TextField
                    onBlur={() => {
                      dataProvider.postAnalyticalEvent(
                        "onBoardingScreen1",
                        "enter Email",
                        {
                          flow: "onboarding",
                          funnel: "Onboarding",
                          user_input: "Tap",
                          ui_component: "Input Box",
                          identifier: form_fields.email,
                        }
                      );
                    }}
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Enter your email id"
                    required
                    className={styles.emailInput}
                    id="formGroupExampleInput"
                    onSelect={handleNext}
                    maxLength={150}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setLoginError(false);
                      if (otpFlag) {
                        setOtpFlag(false);
                        setOtp("");
                        setLoadingText(loadingString);
                      }
                    }}
                  />
                  {otpFlag ? (
                    <div
                      className={`${styles.getStarted} ${styles.feildLabel}`}
                    >
                      <span className={styles.emailSentTo}>
                        OTP / Verification Code has been sent on {email}
                      </span>
                      <TextField
                        onBlur={() => {
                          dataProvider.postAnalyticalEvent(
                            "onBoardingScreen1",
                            "enterOtp",
                            {
                              flow: "onboarding",
                              funnel: "onboarding",
                              user_input: "Tap",
                              ui_component: "Input Box",
                              identifier: "OTP",
                            }
                          );
                        }}
                        type="tel"
                        variant="outlined"
                        placeholder="Enter OTP / Verification Code"
                        size="small"
                        required
                        className={styles.otpText}
                        value={otp}
                        onChange={captureOtp}
                        inputProps={{ maxLength: 6 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className={styles.resendOtp}>
                              <Link onClick={resendOtp}>Resend</Link>
                            </InputAdornment>
                          ),
                        }}
                      />{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                  {loginError && (
                    <span className={styles.invalidError}>{errorText}</span>
                  )}
                  {!otpFlag ? (
                    <span className={styles.tnc}>
                      Make sure to keep this window open while you check your
                      inbox.
                    </span>
                  ) : null}
                  <span className={`${styles.tnc} mb-3`}>
                    By signing up, you agree to our{" "}
                    <a
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      href="https://www.exlyapp.com/static/html/terms.html"
                    >
                      Terms
                    </a>{" "}
                    and{" "}
                    <a
                      target={"_blank"}
                      href="https://www.exlyapp.com/static/html/privacy_policy.html"
                      rel={"noopener noreferrer"}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </span>
                  <Button
                    disabled={loginError}
                    className={styles.continueEmail}
                    type="submit"
                    onClick={!otpFlag ? handleSendOtp : validateOtp}
                  >
                    <span>
                      {(() => {
                        if (!signUpLoad) {
                          if (!otpFlag) {
                            return loadingText;
                          } else {
                            if (!isDesktop) {
                              return "Validate";
                            } else {
                              return "Validate OTP / Verification Code";
                            }
                          }
                        } else return "Loading..";
                      })()}
                    </span>
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
};
export default SignUp;
