import React from "react";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { is_desktop } from "../../../../utils/Utils";

import styles from "./carousal.module.css";
import { ProfileItems } from "./profileItems";
import { is_empty } from "utils/validations";
import IndiaFeatureText from "./IndiaFeatureText";
import {
  getBrowserTimezoneOption,
  isTimezoneIndian,
} from "utils/dateTimeUtils";
import UsaFeatureText from "./UsaFeatureText";

export const slider_data_in = [
  {
    id: "saurabh",
    name: "Saurabh",
    groupName: "Founder Saurabh Fit-Group",
    testimonial:
      "Exly helped me gain 5X more clients. Thanks to its advanced lead capturing funnel which helped me convert high-intent leads faster.",
    path: require("../../../../assets/images/Saurabh.svg"),
  },
  {
    id: "faizal",
    name: "CA Afzal",
    groupName: "Finance",
    testimonial:
      "Exly grew my revenue by 10x. Discount Codes, Early Bird Offers, and the Email marketing tool helped me improve conversion & retention by 50%.",
    path: require("../../../../assets/images/faizal.png"),
  },
];

export const slider_data_us = [
  {
    id: "sara",
    name: "Sara",
    groupName: "Life Coach",
    testimonial:
      "I can honestly say the entire Exly team wants to see each of their clients succeed in our specialized areas.  I will be forever grateful for the time and dedication they have invested into my website and my business!",
    path: require("../../../../assets/images/sara_us.png"),
  },
];

let sliderDataList = [];

export const SignUpCard = ({ showTestimonialFrom }) => {
  const browserTimezone = getBrowserTimezoneOption();

  const [testimonial, setTestimonial] = React.useState(null);
  /**
   * Based on the timezone of the  slider data will be different of US and IN
   */
  const isIndianTimeZone = isTimezoneIndian(browserTimezone?.value);

  const sliderData = isIndianTimeZone ? slider_data_in : slider_data_us;
  const trustText = isIndianTimeZone
    ? "Trusted By 100,000+ Professionals & Artists"
    : "Trusted By 10K+ Creators, Coaches, and Professionals";

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          textAlign: !is_desktop ? "center" : "end",
          fontSize: "20px",
          position: !is_desktop ? "relative" : "absolute",
        }}
      >
        {dots}
      </div>
    ),
  };

  React.useEffect(() => {
    if (!is_empty(showTestimonialFrom)) {
      const index = sliderData.findIndex(
        (data) => data.id === showTestimonialFrom
      );
      if (index !== -1) {
        sliderDataList = [
          <ProfileItems
            key={sliderData[index].name}
            name={sliderData[index].name}
            groupName={sliderData[index].groupName}
            path={sliderData[index].path}
          />,
        ];
        setTestimonial(sliderData[index].testimonial);
      }
    } else {
      sliderDataList = [
        <ProfileItems
          key={sliderData[0].name}
          name={sliderData[0].name}
          groupName={sliderData[0].groupName}
          path={sliderData[0].path}
        />,
      ];
      setTestimonial(sliderData[0].testimonial);
    }
  }, [showTestimonialFrom]);

  return (
    <div className={styles.signupCardContainer}>
      <Card className={styles.testimonialCard}>
        <div className={styles.mobileBorder}>
          <div className={styles.carousalIntroSubContainer}>
            <div className={styles.carousal}>
              <FormatQuoteIcon
                className={styles.quote}
                fontSize={!is_desktop ? "small" : "large"}
              />
            </div>
            <text className={styles.text}>{testimonial}</text>
          </div>
        </div>
        <Slider {...settings}>{sliderDataList}</Slider>
      </Card>
      <br />
      <Divider className={styles.divider} />

      <div className={styles.slider}>
        <VerifiedUserIcon className={styles.verifiedIcon} />
        <text className={styles.trustText}>{trustText} </text>
      </div>
      {isIndianTimeZone ? <IndiaFeatureText /> : <UsaFeatureText />}
    </div>
  );
};
