import React from 'react';
import { AppBar, ToolBar, IconButton, Toolbar } from '@material-ui/core';
import { ReactComponent as Logo } from "../../../assets/vectors/Exly.svg";

const PreLoginAppBar = () => {
    return (
        <AppBar position="static" color="inherit">
            <Toolbar>
                <IconButton style={{ width : '100 %' }}>
                    <Logo />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default PreLoginAppBar;